<template>
  <div>
    <div style="padding:40px 0 0 40px">
      <el-input v-model="query.coachName" placeholder="请输入教练姓名" class="inPut"></el-input>
      <el-date-picker
          style="margin-right:10px"
          v-model="v1"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
      />
      <el-cascader
          v-model="query.deptId"
          style="width: 177px;margin-right: 10px;"

          placeholder="请选择部门"
          :props="{
                        value: 'id',
                        label: 'name',
                        children: 'children',
                        checkStrictly: true}"
          :options="deptList"/>
      <el-button type="primary" @click="search()">搜索</el-button>
      <el-button type="primary" v-permission="pList1"
                 @click="questionDialog = true" icon="el-icon-s-tools">管理
      </el-button>
      <el-popconfirm
          title="确定导出吗？"
          @confirm="exportBtn"
      >
        <template #reference>
          <el-button v-permission="pList2" class="background-animation">导出</el-button>
        </template>
      </el-popconfirm>
    </div>
    <div style="padding:20px 40px 0 40px">
      <el-table :data="questionList" border
                :cell-style="{ padding: 4 + 'px' }"
      >
        <el-table-column label="所属校区" show-overflow-tooltip="true" min-width="120">
          <template #default="scope">
            <div v-if="scope.row.remarkObject">
              {{ scope.row.remarkObject.campusName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="教练姓名">
          <template #default="scope">
            <div v-if="scope.row.remarkObject">
              {{ scope.row.remarkObject.coachName == null ? '暂无信息' : scope.row.remarkObject.coachName }}
            </div>
          </template>
        </el-table-column>
        <el-table-column v-for="(e,i) in defaultData" :key="i" :label="e.formContent">
          <template #default="scope">
            {{ scope.row.applyContentObject[e.formKey] ? scope.row.applyContentObject[e.formKey] : 0 }}
          </template>
        </el-table-column>
        <el-table-column show-overflow-tooltip="true" prop="yesterdayDate" label="沟通日期" min-width="140">
          <template #default="scope">
            <div v-if="scope.row.remarkObject">
              {{ scope.row.remarkObject.yesterdayDate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="沟通图片">
          <template #default="scope">
            <div v-if="scope?.row?.remarkObject?.screenShot">
              <el-image
                  style="height:40px;width:40px"
                  :src="scope.row.remarkObject.screenShot[0]"
                  :zoom-rate="1.2"
                  :preview-src-list="scope.row.remarkObject.screenShot"
                  :initial-index="4"
                  fit="cover"
              >
                <template #error>
                  <div style="font-size:14px">
                    暂无图片
                  </div>
                </template>
              </el-image>
            </div>

          </template>
        </el-table-column>
        <el-table-column prop="remark" label="备注信息" show-overflow-tooltip>
          <template #default="scope">
            <div v-if="scope.row.remarkObject">
              {{ scope.row.remarkObject.remark }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="query.pageIndex"
            :page-size="query.pageSize"
            :total="total"
            @current-change="handlePageChange"
        ></el-pagination>
      </div>
    </div>
    <el-dialog v-model="questionDialog" title="问卷调查管理">
      <div style="margin-bottom:40px">
        <span>部门提报数据字段：</span>
        <el-select v-model="rules.checkedModuleIds" multiple placeholder="请添加">
          <el-option v-for="(item,index) in defaultData" :key="index" :label="item.formContent"
                     :value="item.id"></el-option>
        </el-select>
        <el-button style="margin-left: 20px;" type="primary" @click="adDialog = true ">添加</el-button>
        <el-button v-if="rules.checkedModuleIds.length == 1" style="margin-left: 20px;" type="primary"
                   @click="editDialog = true ">编辑
        </el-button>
      </div>
      <div style="margin-bottom:40px">
        <span>数据提报限制时间：</span>
        <el-time-select
            v-model="rules.limitHourStart"
            :max-time="rules.limitHourEnd"
            class="mr-4"
            placeholder="开始限制日期"
            step="01:00"
            start="06:00"
            end="23:00"
        />
        <el-time-select
            v-model="rules.limitHourEnd"
            class="mr-4"
            placeholder="结束限制日期"
            step="01:00"
            start="06:00"
            end="23:00"
        />
      </div>
      <div style="margin-bottom:40px">
        <span>提报教练所属区域：</span>
        <el-cascader
            v-model="rules.checkDeptIds"
            placeholder="请选择部门"
            :props="{
                        value: 'id',
                        label: 'name',
                        children: 'children',
                        checkStrictly: true}"
            :options="deptList"/>
      </div>
      <el-dialog v-model="adDialog" title="添加提报数据名称">
        <div style="padding-left:20px">
          <span class="mr-4">数据名称:</span>
          <el-input style="width:270px" placeholder="请输入" v-model="moduleName"/>
        </div>
        <template #footer>
          <el-button @click="adDialog = false">取消</el-button>
          <el-button type="primary" @click="confirmAdd">确定</el-button>
        </template>
      </el-dialog>
      <el-dialog v-model="editDialog" title="修改提报数据名称">
        <div style="padding-left:20px">
          <span class="mr-4">新数据名称:</span>
          <el-input style="width:270px" placeholder="请输入" v-model="editItem"/>
        </div>
        <template #footer>
          <el-button @click="editDialog = false">取消</el-button>
          <el-button type="primary" @click="confirmItemEdit">确定</el-button>
        </template>
      </el-dialog>
      <template #footer>
        <el-button @click="questionDialog = false">取消</el-button>
        <el-button @click="confirmEdit" type="primary">确定</el-button>
      </template>
    </el-dialog>
    <el-dialog v-model="aaa" height='666px'>
      <iframe src="http://www.yanhuotiyu.com/" class="ifr"></iframe>
    </el-dialog>
  </div>
</template>
<script>
import {
  questionnaireList,
  exportExcel,
  applyModuleList,
  addApplyModule,
  addApplyDeptModule,
  modifyApplyModule
} from '@/api/questionnaire';
import {listXcxDept} from "@/api/dept";

export default {
  data() {
    return {
      pList1: ['set'],
      pList2: ['export'],
      editItem: '',
      editDialog: false,
      moduleName: '',
      aaa: false,
      adDialog: false,
      questionDialog: false,
      deptList: [],
      v1: [],
      rules: {
        checkedModuleIds: [],
        limitHourStart: '',
        limitHourEnd: '',
        checkDeptIds: []
      },
      query: {
        pageIndex: 1,
        pageSize: 10,
        coachName: '',
        endDate: '',
        startDate: '',
        deptId: null,
      },
      questionList: [],
      total: null,
      defaultData: [],
    }
  },
  created() {
    this.getData()
    this.getDept()
    this.getApplyModuleList()
  },
  methods: {
    confirmItemEdit() {
      modifyApplyModule(this.rules.checkedModuleIds[0], this.editItem).then(res => {
        this.editDialog = false
        if (res.data.code == 200) {
          this.$message.success('修改成功！')
          this.getApplyModuleList()
        } else {
          this.$message.error('修改失败！')
        }
      })
    },
    confirmEdit() {
      if (this.rules.checkDeptIds && this.rules.checkDeptIds.length > 1) {
        this.rules.checkDeptIds.splice(0, this.rules.checkDeptIds.length - 1)
      }
      if (this.rules.limitHourStart) {
        let limitHourStartArr = this.rules.limitHourStart.split(':')
        this.limitHourStart = Number(limitHourStartArr[0])
      }
      if (this.rules.limitHourEnd) {
        let limitHourEndArr = this.rules.limitHourEnd.split(':')
        this.limitHourEnd = Number(limitHourEndArr[0])
      }
      addApplyDeptModule(this.rules).then(res => {
        this.questionDialog = false
        if (res.code == 200) {
          this.$message.success(res.data.message)
          this.getData()
        } else {
          this.$message.error('设置失败！')
        }
      })
    },
    confirmAdd() {
      if (!this.moduleName) {
        this.$message.error('请完整填写！')
      } else {
        this.adDialog = false
        addApplyModule(this.moduleName).then(res => {
          if (res.data.code == 200) {
            this.$message.success('添加成功')
            this.getApplyModuleList()
          }
        })
      }
    },
    exportBtn() {
      this.aaa = true
      exportExcel(this.query)
    },
    dateFormat(val) {
      var date = new Date(val);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      const time = parseInt(y) + "-" + parseInt(m) + "-" + parseInt(d);
      return time;
    },
    getData() {
      if (this.query.deptId && this.query.deptId.length > 1) {
        this.query.deptId.splice(0, this.query.deptId.length - 1)
      }
      if (this.v1!=null && this.v1.length >= 2) {
        this.query.startDate = this.dateFormat(this.v1[0]);
        this.query.endDate = this.dateFormat(this.v1[1]);
      }else {
        this.query.startDate = null;
        this.query.endDate = null;
      }
      questionnaireList(this.query).then(res => {
        if (res.code == 200) {
          this.questionList = res.data.records
          this.total = res.data.total
        } else {
          this.$message.error('请求数据失败！')
        }
      })
    },
    handlePageChange(e) {
      this.query.pageIndex = e
      this.getData()
    },
    search() {
      this.query.pageIndex = 1
      this.getData()
    },
    getTypeList(commodityType) {
      if (commodityType != null) {
        commodityType.forEach(items => {
          if (items != null) {
            if (items.children != null && items.children.length > 0) {
              this.getTypeList(items.children);
            } else {
              items.children = undefined;
            }
          }
        });
        return commodityType;
      }
    },
    getDept() {
      listXcxDept().then(res => {
        this.deptList = this.getTypeList(res.data);
      })


    },
    getApplyModuleList() {
      applyModuleList(-1).then(res => {
        if (res.code == 200) {
          this.defaultData = res.data.data.defaultData
        }
      })
    }

  }
}
</script>
<style scoped>
.inPut {
  margin: 0 10px;
  width: 260px
}

.background-animation {
  color: white;
  background: linear-gradient(-45deg, #ff00cc, #5454f8, #00ccff, #00ff33, #ffee00, #5454f8);
  background-size: 600% 600%;
  animation: gradientAnimation 10s ease infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.mr-4 {
  margin-right: 40px;
}

.ifr {
  width: 100%;
  height: 600px;
}
</style>>


import request from '../utils/request';

/**
 * 查询部门列表
 * @param query
 * @returns {AxiosPromise}
 */
export const deptTree = () => {
    return request({
        url: '/sys/dept/tree',
        method: 'GET',
    });
};

//（组织架构也有用这个接口）
export const getListTree = () => {
    return request({
        url: '/sys/dept/listtree',
        method: 'get',
        params: {},
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};


/**
 * 查询部门列表
 * @param query
 * @returns {AxiosPromise}
 */
export const listXcxDept = () => {``
    return request({
        url: '/sys/dept/listtree',
        method: 'GET',
    });
};

/**
 * 获取部门
 * @param query
 * @returns {AxiosPromise}
 */
export const selectList = (list) => {
    return request({
        url: '/sys/dept/selectList',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/**
 * 获取部门所属人员
 * @param query
 * @returns {AxiosPromise}
 */
export const selectUserByDept = (query) => {
    return request({
        url: '/sys/user/selectUserByDept',
        method: 'GET',
        params:query,
    });
};

/**
 * 查询全部部门
 * @param query
 * @returns {AxiosPromise}
 */
export const selectDeptList = () => {
    return request({
        url: '/sys/dept/selectDeptList',
        method: 'GET',
    });
};
/**
 * 添加部门
 * @param query
 * @returns {AxiosPromise}
 */
export const createDept = (list) => {
    return request({
        url: '/sys/dept/add',
        method: 'POST',
        dataType: "json",
        data: JSON.stringify(list),
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//listXcxDept
export const getCompanyName = () => {
    return request({
        url: '/web/region/selectRegion',
        method: 'GET'
    });
};
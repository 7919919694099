import request from '../utils/request';
//问卷调查表格渲染
export const questionnaireList = (query) => {
    return request({
        url: '/sys/questionnaire/questionnaireList',
        method: 'post',
        data:query,
        dataType: "json",
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
}
//导出
export function exportExcel(query) {
    return request({
        url: '/sys/questionnaire/excel',
        method: 'post',
        data: query,
        responseType: 'arraybuffer'
    })
}
//查询日数据所填项
export function applyModuleList(deptId) {
    return request({
        url: '/sys/applyModule/applyModuleList',
        method: 'post',
        params:{
            deptId
        },
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    })
}
//问卷调查 添加提报项
export function addApplyModule(moduleName) {
    return request({
        url: '/sys/applyModule/addApplyModule',
        method: 'post',
        params:{
            moduleName
        },
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    })
}
//问卷调查 添加指定部门的提报项 
export function addApplyDeptModule(data) {
    return request({
        url: '/sys/applyModule/addApplyDeptModule',
        method: 'post',
        data,
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    })
}
export function modifyApplyModule(moduleId,moduleName) {
    return request({
        url: '/sys/applyModule/modifyApplyModule',
        method: 'post',
        params:{
            moduleId,
            moduleName
        },
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    })
}



